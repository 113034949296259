@import 'variables';

body {
  position: relative;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

@media only screen and (max-width: 1202px) {
  body {
    padding: 0 16px;
  }
}

@media only screen and (max-width: $screen-medium) {
  body {
    padding: 0 24px;
  }
}

@media only screen and (max-width: $screen-small) {
  .body {
    max-width: 100vw;
    overflow-x: hidden;
    margin-left: -24px;
    margin-right: -24px;
  }

  .copy-wrapper-mobile {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.prefooter__image {
  position: absolute;
  display: flex;
  align-items: flex-end;
  bottom: 93px;
  right: 16px;
}

.prefooter__image img {
  max-width: 128px;
}

@media only screen and (max-width: $screen-medium) {
  .prefooter__image {
    position: relative;
    bottom: -1px;
    right: 0px;
  }

  .prefooter__image img {
    width: 100%;
    margin: 0 auto;
  }
}
