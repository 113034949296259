@Import 'variables';

.our-target {
  display: flex;
  max-width: $page-width;
  margin: 72px auto 0;
}

@media only screen and (max-width: $screen-small) {
  .our-target {
    margin-top: 48px;
    flex-direction: column-reverse;
  }
}

.our-target__copy {
  flex: 7;
}

@media only screen and (min-width: 769px) and (max-width: $screen-medium) {
  .our-target__copy h2 {
    text-align: left;
    margin-left: 0;
  }
}

.our-target__image {
  display: flex;
  flex: 5;
  padding-right: 72px;
  align-items: flex-start;
}

@media only screen and (max-width: $screen-small) {
  .our-target__image {
    flex: auto;
    position: relative;
    padding-left: 0px;
    margin-left: -24px;
    margin-right: -24px;
    box-shadow: 0 56px $color-primary;
    height: 200px;
  }
}

.our-target__image img {
  position: relative;
  width: 100%;
  height: auto;
  left: -40px;
}

@media only screen and (min-width: 769px) and (max-width: $screen-medium) {
  .our-target__image img {
    width: 300px;
    left: 80px;
  }
}

/* @media only screen and (max-width: $screen-medium) { */
@media only screen and (max-width: $screen-small) {
  .our-target__image img {
    width: 100%;
    height: auto;
    max-width: 300px;
    margin: 0 auto;
    top: 70px;
    position: absolute;
    left: 0px;
  }
}
