@import 'variables';

header {
  display: flex;
  margin: 0 auto 56px;
  padding-top: 16px;
  align-items: center;
  max-width: $page-width;
  width: 100%;
}

@media only screen and (max-width: $screen-small) {
  header {
    margin-bottom: 0;
  }
}

.logo {
  width: 160px;
  height: 140px;
}

.logo img {
  max-width: 160px;
  height: auto
}

.logo-small-mobile {
  display: none;
  width: 80px;
  height: 80px;
}

.logo.logo-small-mobile img {
  width: 100%;
  left: 0px;
}

@media only screen and (max-width: $screen-medium) {
  .logo.logo-small-mobile {
    display: block;
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-medium) {
  .logo {
    width: 120px;
    height: 106px;
  }

  .logo img {
    max-width: 120px;
  }
}

@media only screen and (max-width: $screen-small) {
  .logo {
    margin: 0 auto;
  }

  .logo img {
    position: relative;
    left: -20px;
  }
}

header nav {
  padding-top: 40px;
  margin-left: auto;
  display: flex;
}

@media only screen and (max-width: $screen-small) {
  header nav {
    /*
    display: none;
    */
  }
}

@media only screen and (max-width: $screen-medium) {
  header nav {
    transform: translateX(100%);
    flex-direction: column;
    position: fixed;
    min-height: 100vh;
    top: 0px;
    right: 0px;
    bottom: 0px;
    padding: 16px;
    box-shadow: none;
    z-index: 10;
    background-color: $color-white;
    align-items: center;
    transition: transform 0.25s ease-in-out;
  }

  header nav.nav--open {
    transform: translateX(0%);
    box-shadow: -8px 0 8px rgba(0,0,0,0.08);
  }
}

header nav a {
  font-size: 16px;
  line-height: 24px;
  font-weight: 800;
  text-transform: uppercase;
  color: $grey-light;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
}

@media only screen and (max-width: $screen-medium) {
  header nav a {
    font-size: 14px;
  }
}

header nav a.current-page,
header nav a:hover {
  color: $grey;
}

header nav a + a,
header nav a + .nav-submenu,
.nav-submenu + .nav-submenu{
  margin-left: 16px;
}

header nav a.highlight {
  color: $color-primary;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
}

@media only screen and (max-width: $screen-medium) {
  header nav a.highlight {
    padding: 0px;
  }
}

header nav a.highlight:hover {
  background-color: $color-primary;
  color: $color-white;
}

.nav-submenu {
  position: relative;
}

.nav-submenu ul {
  display: none;
  position: absolute;
  white-space: nowrap;
  background-color: $color-white;
  z-index: 1;
  box-shadow: 0 4px 0 0px $color-white, -4px 4px 0 0px $color-white, 4px 4px 0 0px $color-white;
}

@media only screen and (max-width: $screen-medium) {
  .nav-submenu > a {
    display: none;
  }
  .nav-submenu ul {
    display: flex;
    position: inherit;
    margin-top: 16px;
    margin-bottom: 16px;
    flex-direction: column;
    align-items: center;
  }
}

.nav-submenu:hover ul {
  display: flex;
  flex-direction: column;
}

.mobile-menu {
  position: absolute;
  display: none;
  width: 40px;
  height: 40px;
  top: 8px;
  left: -56px;
  transform: rotate(0deg);
  transition: transform 0.25s ease-in-out;
}

.mobile-menu:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 4px;
  right: 4px;
  height: 4px;
  background-color: $color-black-1;
  box-shadow: 0 10px $color-black-1, 0 20px $color-black-1;
}

header nav.nav--open .mobile-menu {
  transform: rotate(-90deg);
}


@media only screen and (max-width: $screen-medium) {
  .mobile-menu {
    display: block;
  }
}
