@import 'variables';

.what-we-do {
  max-width: 974px;
  margin: 64px auto 0;
}

@media only screen and (max-width: screen-medium) {
  .what-we-do {
    max-width: 974px;
    margin-top: 96px;
  }
}

.what-we-do__icons {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
  margin-top: 64px;
}

@media only screen and (max-width: screen-medium) {
  .what-we-do__icons {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.what-we-do__icon {
  width: 33%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 16px;
}

.what-we-do__icon img {
  transition: transform 0.25s ease-in-out;
}

.what-we-do__icon p {
  transition: color 0.25s ease-in-out, transform 0.25s ease-in-out;
}

@media only screen and (max-width: screen-medium) {
  .what-we-do__icon {
    width: 50%;
  }
}

.what-we-do__icon p {
  margin-top: 8px;
  font-size: 16px;
  line-height: 22px;
}

@media only screen and (max-width: screen-medium) {
  .what-we-do__icon p {
    margin-top: 16px;
    font-size: 12px;
    line-height: 16px;
    max-width: 200px;
  }
}
