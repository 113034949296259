@import 'variables';

h1, h2, h3 {
  font-family: 'Abril Fatface', serif;
  color: $color-black-2;
  margin-bottom: 40px;
}

body, p, a {
  font-family: 'Open Sans', sans-serif;
  color: $grey;
}

h1 {
  font-size: 72px;
  line-height: 98px;
}

h2 {
  font-size: 55px;
  line-height: 75px;
}

h3 {
  font-size: 72px;
  line-height: 98px;
  text-decoration: underline;
  color: var($color-primary);
}

@media only screen and (max-width: $screen-medium) {
  h1, h2, h3 {
    text-align: center;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: $screen-medium) {
  h1, h2, h3 {
    text-align: center;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: $screen-medium) {
  h2 {
    margin-left: -24px;
    margin-right: -24px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 24px;
  }

  h2.green-block {
    background-color: $color-primary;
  }
}

p {
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (max-width: $screen-medium) {
  p {
    font-size: 14px;
    line-height: 20px;
  }

  h1 + p,
  h2 + p {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}
