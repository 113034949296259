@import 'variables';

.our-tools {
  display: flex;
  flex-direction: column;
  max-width: $page-width;
  margin: 32px auto 0;
}

@media only screen and (max-width: $screen-medium) {
  .our-tools {
    margin-top: 96px;
  }
}

.our-tools__listing {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media only screen and (max-width: $screen-medium) {
  .our-tools__listing {
    flex-direction: column;
    max-width: 600px;
    margin: 16px auto 0;
    width: 100%;
  }
}

ul.our-tools__list {
  flex: 1;
  max-width: 320px;
}

ul.our-tools__list li {
  position: relative;
  padding-left: 16px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
  color: $grey;
  font-weight: 700;
  transition: color 0.25s ease-in-out;
}

@media only screen and (max-width: $screen-medium) {
  ul.our-tools__list li {
    font-size: 12px;
    line-height: 16px;
  }
}

ul.our-tools__list li:before {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: 8px;
  left: 0px;
  border-radius: 100%;
  background-color: $grey;
}
