.prefooter__image {
  position: absolute;
  display: flex;
  align-items: flex-end;
  bottom: 93px;
  right: 16px;
}

.prefooter__image img {
  max-width: 128px;
}

@media only screen and (max-width: $screen-medium) {
  .prefooter__image {
    position: relative;
    bottom: -1px;
    right: 0px;
  }

  .prefooter__image img {
    width: 100%;
    margin: 0 auto;
  }
}

footer {
  position: relative;
  padding: 10px 0px;
  background-color: $color-primary;
  box-shadow: -500px 0px $color-primary, 500px 0px $color-primary;
}

@media only screen and (max-width: $screen-medium) {
  footer {
    position: relative;
    padding: 10px 0px;
    background-color: $color-primary;
    box-shadow: -32px 0px $color-primary, 32px 0px $color-primary;
    margin-left: -8px;
    margin-right: -8px;
  }
}

footer p {
  color: $grey;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

@media only screen and (max-width: $screen-medium) {
  footer p {
    font-size: 12px;
    line-height: 16px;
  }
}

.footer__credits {
  margin-top: 8px;
  font-size: 12px;
}

.footer__credits a {
  display: inline-block;
  position: relative;
  line-height: 22px;
  text-decoration: none;
  transition: color 0.25s ease-in-out;
}

.footer__credits a:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 4px;
  left: 0;
  background: $grey;
}

.footer__credits a:hover {
  color: $color-black;
}

.footer__credits a:hover:before {
  background: linear-gradient(-45deg, $color-black, #EE7752, #E73C7E, #23A6D5, #23D5AB, $color-black);
  background-size: 500% 500%;
  animation: footer-credits-border-animation 7s ease infinite;
}

@keyframes footer-credits-border-animation {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}
