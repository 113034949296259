@import 'variables';

@media only screen and (max-width: $screen-medium) {
  .layout_basic__content {
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;    
  }
}

.layout_basic__content h1,
.layout_basic__content h2,
.layout_basic__content h3 {
  margin-bottom: 16px;
}

@media only screen and (max-width: $screen-medium) {
  .layout_basic__title {
    text-align: left;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.layout_basic__content h1 {
  font-size: 72px;
  line-height: 98px;
}

.layout_basic__content h2 {
  font-size: 55px;
  line-height: 75px;
}

.layout_basic__content h3 {
  font-size: 40px;
  line-height: 58px;
  text-decoration: none;
}

.layout_basic__content h4 {
  font-size: 32px;
  line-height: 42px;
  text-decoration: none;
}

.layout_basic__content img {
  max-width: 100%;
}

.layout_basic__content table th,
.layout_basic__content table td {
  padding: 8px;
  color: $color-black-2;
  border: 1px solid $grey-light;
}

.layout_basic__content table th {
  font-weight: 700;
}

.layout_basic__content table + *,
.layout_basic__content p + *,
.layout_basic__content ol + *,
.layout_basic__content ul + *{
  margin-top: 1em;
}

.layout_basic__content hr {
  margin-top: 1.8em;
  margin-bottom: 1.8em;
  border: 1px solid $color-white;
}

@media only screen and (max-width: $screen-medium) {
  .layout_basic__content hr {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.layout_basic__content ol {
  counter-reset: section;
}

.layout_basic__content ul li,
.layout_basic__content ol li {
  position: relative;
  padding-left: 16px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
  color: $grey;
  font-weight: 700;
}

.layout_basic__content ul li:before,
.layout_basic__content ol li:before {
  content: '';
  display: block;
  position: absolute;
  left: 0px;
}

.layout_basic__content ul li:before {
  width: 8px;
  height: 8px;
  top: 8px;
  border-radius: 100%;
  background-color: $grey;
}

.layout_basic__content ol li:before {
  counter-increment: section;
  content: counter(section);
}
