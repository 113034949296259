$color-white: #ffffff;
$color-white-1: #f7f7f7;
$color-white-2: #ebe8e8;
$color-white-3: #dddddd;
$grey: #555555;
$grey-light: #AAAAAA;
$color-black: #000000;
$color-black-1: #2b2b2b;
$color-black-2: #333333;
$color-black-3: #666666;
$color-primary: #A4CD3A;
$color-highlight: #00FFC2;
$page-width: 1170px;

$screen-small: 768px;
$screen-medium: 1024px;
