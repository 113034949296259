@import 'variables';

.hero {
  flex: 1;
  display: flex;
  max-width: $page-width;
  margin: 0 auto;
}

@media only screen and (max-width: $screen-small) {
  .hero {
    flex-direction: column;
  }
}

.hero__copy {
  padding-top: 65px;
  flex: 7;
}

@media only screen and (max-width: $screen-medium) {
  .hero__copy h1 {
    text-align: left;
  }
}

@media only screen and (max-width: $screen-small) {
  .hero__copy {
    padding-top: 32px;
    flex: 7;
  }

  .hero__copy h1 {
    text-align: center;
  }
}

.hero__image {
  display: flex;
  flex: 5;
  padding-left: 72px;
  align-items: flex-start;
  position: relative;
}

@media only screen and (max-width: $screen-small) {
  .hero__image {
    flex: auto;
    padding-left: 0px;
    margin-left: -24px;
    margin-right: -24px;
    box-shadow: 0 56px $color-primary;
    height: 200px;
  }
}

.hero__image img {
  position: absolute;
  top: 50%;
  margin-top: -160px;
  right: -86px;
  width: 505px;
}

@media only screen and (max-width: $screen-medium) {
  .hero__image img {
    margin-top: -80px;
    width: 400px
  }
}

@media only screen and (max-width: $screen-small) {
  .hero__image img {
    width: 100%;
    height: auto;
    max-width: 300px;
    margin: 0 auto;
    top: 73px;
    position: absolute;
    right: -32px;
  }
}
