@import 'variables';

.contacts {
  display: flex;
  padding-bottom: 128px;
  width: 100%;
  max-width: $page-width;
  margin: 96px auto 0px;
}

@media only screen and (min-width: $screen-medium + 1) {
  .contacts {
    min-height: calc(50vh - 136px);
  }
}

@media only screen and (max-width: $screen-small) {
  .contacts {
    flex-direction: column;
    margin-top: 40px;
    padding-bottom: 32px;
  }
}

.contacts h3 {
  margin-bottom: 32px;
  color: $color-primary;
}

@media only screen and (max-width: $screen-small) {
  .contacts h3 {
    margin-bottom: 16px;
  }
}

.contacts__section {
  flex: 1;
  padding-left: 72px;
  flex-direction: column;
}

@media only screen and (max-width: $screen-medium) {
  .contacts__section {
    padding-left: 0;
  } 
}

@media only screen and (max-width: $screen-small) {
  .contacts__section {
    padding-left: 0px;
    text-align: center;
  }

  .contacts__section + .contacts__section {
    margin-top: 32px;
  }
}

.contacts__section p {
  font-size: 24px;
  line-height: 34px;
}

@media only screen and (max-width: $screen-small) {
  .contacts__section p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media only screen and (max-width: $screen-medium) {
  .contacts__section p {
    text-align: center;
  }
}

.contacts__section p a {
  font-weight: 700;
  color: $color-black-2;
  transition: color 0.25s ease-in-out;
}

.contacts__section p a:hover {
  color: $color-primary;
}

.contacts__section p a:not(.underline) {
  text-decoration: none;
}
